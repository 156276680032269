import { useLazyQuery } from '@apollo/client'
import { Select, Tag } from 'antd'
import _ from 'lodash'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect'
import React, { useContext, useEffect } from 'react'
const { Option } = Select

// global component
import { Row } from 'components/Layout'
import { Text } from 'components/Text'
import DisplayTaggedPlayers from './DisplayTaggedPlayers'

// import gql
import { query } from 'graphql/mediaGallery'

// context
import { TaggingFilesContext } from './TaggingLayout'

const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault()
        event.stopPropagation()
    }
    return (
        <Tag
            color={'red'}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ marginRight: 3 }}
        >
            {label}
        </Tag>
    )
}

const TagPlayers = () => {
    const {
        mediaId,
        editMode,
        selectedClubId,
        taggedPlayersInfo,
        selectedPlayers,
        setSelectedPlayers,
        setDeSelectedPlayers,
    } = useContext(TaggingFilesContext)
    const [getPlayers, { loading, error, data: players }] = useLazyQuery(
        query.GET_PLAYERS,
    )

    useEffect(() => {
        function fetchPlayers() {
            if (!selectedClubId) return

            getPlayers({
                variables: { where: { club_id: { _eq: selectedClubId } } },
            })
        }

        fetchPlayers()
    }, [selectedClubId])

    const handleOnSelect = async (value: number | number[]) => {
        setSelectedPlayers((prev: any) => [...prev, value])
    }

    const handleOnDeselect = async (value: number | number[]) => {
        setSelectedPlayers((prevArray: any) =>
            _.filter(prevArray, (prevValue) => prevValue !== value),
        )
        setDeSelectedPlayers((prev: any) => [...prev, value])
    }

    return (
        <>
            {editMode ? (
                <Row rWidth={'50%'}>
                    <Row rWidth={'20%'}>
                        <Text>{'Tag Players:'}</Text>
                    </Row>
                    <Row rWidth={'80%'}>
                        <Select
                            tagRender={tagRender as any}
                            loading={loading}
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="--Select Players--"
                            onSelect={handleOnSelect}
                            onDeselect={handleOnDeselect}
                            value={selectedPlayers}
                            maxTagCount="responsive"
                        >
                            {selectedClubId &&
                                players?.players_details?.map(
                                    (player: {
                                        id: React.Key | null | undefined
                                        user: {
                                            first_name: any
                                            last_name: any
                                        }
                                    }) => (
                                        <Option
                                            value={player.id}
                                            key={player.id}
                                        >{`${player?.user?.first_name} ${player?.user?.last_name}`}</Option>
                                    ),
                                )}
                        </Select>
                    </Row>
                </Row>
            ) : (
                <DisplayTaggedPlayers mediaId={mediaId} />
            )}
        </>
    )
}

export default TagPlayers
